<script lang="ts" setup>
defineProps({
  title: {
    type: String,
    required: false,
  },
});

const slots = useSlots();
</script>

<template>
  <div
    class="bg-white z-[999] flex flex-row items-center justify-between top-0 w-full h-[60px] shadow"
  >
    <!-- {{ $slots.leading ? $slots.leading() : false }} -->

    <div class="flex flex-row items-center h-full">
      <div v-if="!!slots.leading" class="pl-2 h-full flex flex-row items-center">
        <slot name="leading" />
      </div>

      <span v-if="title" class="text-h5 pl-4">
        {{ title }}
      </span>
    </div>
    <div>
      <div v-if="!!slots.actions" class="pr-4 flex flex-row items-center">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
